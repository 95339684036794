import React from 'react';
import Marquee from "react-fast-marquee";

import './skills.css'

// import { ThemeContext } from '../../contexts/ThemeContext';
import { skillsData } from './skillsData'
import { skillsImage } from './skillsImage'

function Skills() {
    const skillBoxStyle = {
        backgroundColor: "white",
        boxShadow: `2px 2px 20px`
    }
    return (
        <div id ="skills"className="skills">
            <div className="skillsHeader">
                <h1 style={{fontFamily:"'Secular One' , sans-serif", fontSize:"40px"}}>Skills</h1>
            </div>
            <div className="skillsContainer">
                <div className="skill--scroll">
                    <Marquee 
                        gradient={false} 
                        speed={90} 
                        pauseOnHover={true}
                        pauseOnClick={true} 
                        delay={0}
                        play={true} 
                        direction="left"
                    >
                        {skillsData.map((skill, id) => (
                            <div className="skill--box" key={id} style={skillBoxStyle}>
                                <img src={skillsImage(skill)} alt={skill} />
                                <h3>
                                    {skill}
                                </h3>
                            </div>
                        ))}
                    </Marquee>
                </div>
            </div>
        </div>
    )
}

export default Skills





// import React from 'react'

// import "./skills.css";
// const Skills = () => {
//   return (
//     <div className="skills" id="skills">Skills</div>
//   )
// }

// export default Skills