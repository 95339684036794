import React from "react";
import ResumeButton from '../Components/ResumeButton'
function Home() {
  return (
    <div className="home" id="home">
      {/* <Animation/> */}
      <h3 style={{ color: "#FA7268", paddingTop: "90px" }}>
        Hi There!{" "}
        <span role="img" aria-labelledby="Hello" style={{ color: "white" }}>
          👋🏻
        </span>{" "}
        I'm{" "}
      </h3>
      <h1 style={{color:"white"}}>Naitik Patel</h1>
      <h2 style={{ color: "#FA7268" }}>MERN Stack Developer</h2>
      <div className="hr">
        <hr />
      </div>

        {/* <a href="NAITIK PATEL.pdf" target="_blank"> */}
          <ResumeButton/>
        {/* </a> */}
        
    </div>
  );
}

export default Home;
// download="Naitik_Patel_Resume_13-04-2022.pdf
