import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "../App.css"

const NavBar = () => {
  const [navbarColor, setNavbarColor] = useState('transparent');
  const [navbarText, setNavbarText] = useState("dark");

  const handleClick = () => {
    if (navbarColor === "transparent") {
      setNavbarColor('light');
      setNavbarText("light");
    }
    // else{
    //   setNavbarColor('transparent');
    //   setNavbarText("dark");
    // }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 700) {
        setNavbarColor('light');
        setNavbarText("light");
      }
      else{
        setNavbarColor('transparent');
        setNavbarText("dark");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Navbar style={{zIndex:1}} variant={navbarText} bg={navbarColor} expand="lg" fixed="top" className="navbar">
        <Container >
        <Navbar.Brand className="fs-4 fw-bolder" href="#">Portfolio</Navbar.Brand>
          <Navbar.Toggle onClick={handleClick} aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 fw-bolder"
              style={{ maxHeight: "auto" }}
              navbarScroll
            >
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#skills">Skills</Nav.Link>
              <Nav.Link href="#project">Project</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <header>
        <h1 class="logo">Logo</h1>
        <input type="checkbox" id="nav-toggle" class="nav-toggle"></input>
        <nav>
          <ul>
            <li>
              <a href="#home">Home</a>
            </li>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#skills">Blog</a>
            </li>
            <li>
              <a href="#project">Contact</a>
            </li>
          </ul>
        </nav>
        <label for="nav-toggle" class="nav-toggle-label">
          <span></span>
        </label>
      </header> */}
    </>
  );
};

export default NavBar;
