import React from "react";
import AnimatedCursor from "react-animated-cursor";
import { useState, useEffect } from "react";
export function Cursor() {
  const [navbarText, setNavbarText] = useState("255,255,255");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        // setNavbarColor('#333');
        setNavbarText("255,69,0");
      }
      else{
        setNavbarText("255,255,255");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="App">
      <AnimatedCursor
        innerSize={10}
        outerSize={10}
        // color="255,255,255"
        color={navbarText}
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
    </div>
  );
}

export default Cursor;
