import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React from "react";
import Cursor from "./Components/Cursor";
import Home from "./Components/Home";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Project from "./Components/Projects/Project";
import NavBar from "./Components/NavBar";
import { BrowserRouter } from "react-router-dom";
import Skills from "./Components/Skills/Skills";
import Footer from "./Components/Footer";
// import { Analytics } from '@vercel/analytics/react'
// import { ChakraProvider } from '@chakra-ui/react'

function App() {
  return (
    // <ChakraProvider>
    <BrowserRouter>
      <div className="App">
        <Cursor />
        <NavBar />
        <Home />
        <About />
        <Skills/>
        <Project />
        <Contact />
        <Footer/>
        {/* <Analytics/> */}
      </div>
    </BrowserRouter>
    // </ChakraProvider>
  );
}

export default App;
