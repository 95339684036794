import React from "react";
import '../App.css'
const ResumeButton = () => {
  return (
    <div>
      {/* <button type="button" className="btn btn-success">View Resume</button> */}
      <div className="buttons">
        <div className="dark">
          <div>
            {/* <a className="btn btn-white btn-big" href="NAITIK PATEL.pdf" target="_blank">View Resume</a>
            <a className="btn btn-white btn-mid" href="NAITIK PATEL.pdf" target="_blank">View Resume</a> */}
            <a className="btn btn-white" href="NAITIK PATEL.pdf" target="_blank">View Resume</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeButton;
