import React from "react";
import { useState, useEffect } from "react";
// import ProjectCard from "./ProjectCard";
import data from "./data.json";
import "../../App.css";
import Card1 from "../Card1";
import Card2 from "../Card2";
// import './project.css'

function Project() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const query = window.matchMedia("(max-width: 1000px)");
    setIsMobile(query.matches);
    query.addListener(handleMediaChange);
    return () => {
      query.removeListener(handleMediaChange);
    };
  }, []);

  function handleMediaChange(e) {
    setIsMobile(e.matches);
  }
  return (
    <div className="project" id="project">
      <h1 style={{fontFamily:"'Secular One' , sans-serif", fontSize:"40px"}}>Projects</h1>
      <div className="card-style">
        {/* <div className="project-display">
      {data.Projects.map(function (project, i) {
                return (
                  <ProjectCard key={i} project={project}/>
                );
              })}
    </div> */}

        {isMobile
          ? data.Projects.map(function (project, i) {
              return <Card1 key={i} project={project} i={i} />;
            })
          : data.Projects.map(function (project, i) {
              return i % 2 === 0 ? (
                <Card1 key={i} project={project} i={i} />
              ) : (
                <Card2 key={i} project={project} i={i} />
              );
            })}
      </div>
    </div>
  );
}

export default Project;
