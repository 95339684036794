import React from "react";
import ".././App.css";
import { FaHeart } from "react-icons/fa";
import { FaRegCopyright } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
      <h6> <b>Made with <FaHeart style={{marginLeft:"5px",marginRight:"5px"}} color="Red"/> | Naitik Patel | <FaRegCopyright style={{marginLeft:"5px",marginRight:"5px"}}/> 2023</b></h6>
      {/* <i class="fa-duotone fa-heart"></i> */}
    </div>
  );
};

export default Footer;
