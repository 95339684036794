import React from "react";
// import { SocialIcon } from "react-social-icons";
import "./icons.css";
import "../App.css";
import { SiLeetcode } from "react-icons/si";
function Contact() {
  return (
    <div className="contact" id="contact">
      <h1 style={{fontFamily:"'Secular One' , sans-serif"}}>Coding Profiles</h1>
      <ul className="wrapper">
        <a href="https://www.hackerrank.com/naitikpatel_2002" rel="noreferrer" target="_blank">
          <li className="icon HackerRank">
            <span className="tooltip">HackerRank</span>
            <span>
              <i
                style={{ fontStyle: "normal" }}
                className="fa-brands fa-hackerrank"
              ></i>
            </span>
          </li>
        </a>

        <a href="https://github.com/naitikp2002" rel="noreferrer" target="_blank">
          <li className="icon github">
            <span className="tooltip">Github</span>
            <span>
              <i className="fab fa-github" />
            </span>
          </li>
        </a>
        <a href="https://leetcode.com/naitikp2002/" rel="noreferrer" target="_blank">
          <li className="icon leetcode">
            <span className="tooltip">Leetcode</span>
            <span>
              <SiLeetcode size="1em" />
            </span>
          </li>
        </a>
      </ul>

      <h1 style={{fontFamily:"'Secular One' , sans-serif"}}>Let's Connect</h1>
      <ul className="wrapper">
        <a href="mailto:naitikpatel2002@gmail.com" rel="noreferrer" target="_blank">
          <li className="icon Email">
            <span className="tooltip">Email</span>
            <span>
              <i className="fa fa-envelope fa-2xl" />
            </span>
          </li>
        </a>
        <a href="https://twitter.com/Naitikp06876194" rel="noreferrer" target="_blank">
          <li className="icon twitter">
            <span className="tooltip">Twitter</span>
            <span>
              <i className="fab fa-twitter" />
            </span>
          </li>
        </a>
        <a href="https://www.instagram.com/naitikp_05/" rel="noreferrer" target="_blank">
          <li className="icon instagram">
            <span className="tooltip">Instagram</span>
            <span>
              <i className="fab fa-instagram" />
            </span>
          </li>
        </a>
        <a href="https://www.linkedin.com/in/naitik-patel-19567b20a/" rel="noreferrer" target="_blank">
          <li className="icon linkedin">
            <span className="tooltip">LinkedIn</span>
            <span>
              <i
                style={{ fontStyle: "normal" }}
                className="fa-brands fa-linkedin-in"
              ></i>
            </span>
          </li>
        </a>
      </ul>
    </div>

  );
}

export default Contact;
