import React, { Fragment, useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import "./About.css";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Card,
} from "@chakra-ui/react";

const About = () => {
  const [toggleTab, setToggleTab] = useState(1);
  const toggleState = (index) => {
    setToggleTab(index);
  };
  return (
    <Fragment>
      <ScrollToTop smooth viewBox="0 0 175 256" />
      <section id="about" className="about">
        <h1
          style={{ fontFamily: "'Secular One' , sans-serif", fontSize: "40px" }}
        >
          {" "}
          About me{" "}
        </h1>
        <div className="row">
          <div className="column">
            <div className="about-img"></div>
          </div>

          <div className="column">
            <div className="tabs">
              <div
                className={
                  toggleTab === 1 ? "single-tab active-tab" : "single-tab"
                }
                onClick={() => toggleState(1)}
              >
                <h2>About</h2>
              </div>

              <div
                className={
                  toggleTab === 2 ? "single-tab active-tab" : "single-tab"
                }
                onClick={() => toggleState(2)}
              >
                <h2>Achievements</h2>
              </div>

              <div
                className={
                  toggleTab === 3 ? "single-tab active-tab" : "single-tab"
                }
                onClick={() => toggleState(3)}
              >
                <h2>Experience</h2>
              </div>
            </div>

            <div className="tab-content">
              {/* About Content */}
              <div
                className={
                  toggleTab === 1 ? "content active-content" : "content"
                }
              >
                <Card
                  // marginTop={"20px"}
                  bg={"white"}
                  padding={"1.5rem"}
                >
                  <p style={{ paddingBottom: "0" }}>
                    I am <b>Naitik Patel</b>, pursuing B.Tech in Computer
                    Science at CHARUSAT University.
                  </p>
                  <p>
                    As a web developer, I am responsible for the coding,
                    programming, and designing of websites. I work with my team
                    to create the layout and look of websites. I also help to
                    maintain and improve existing websites. My skills include
                    expertise in MERN stack. I am a motivated and hardworking
                    individual who is always looking to improve my skills. I am
                    a quick learner and I am always willing to take on new
                    challenges. I am a team player and I work well under
                    pressure. I am also comfortable working independently when
                    needed.
                  </p>
                </Card>
              </div>
              {/* Achievement Content */}

              <div
                className={
                  toggleTab === 2 ? "content active-content" : "content"
                }
              >
                {/* <div className="lc-gif">
                  <img
                    src="2022-annual-100.gif"
                    width="170px"
                    alt="loading..."
                  />
                  
                </div>
                <p style={{textAlign:"center"}}>
                  <b>100</b> Days Leetcode Badge 2022, solved <b>250+</b> problems on Leetcode
                </p> */}
                <Accordion defaultIndex={[0]} allowToggle bg={"white"}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          100 Days Leetcode Badge 2022
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <div className="lc-gif">
                        <img
                          src="2022-annual-100.gif"
                          width="170px"
                          alt="loading..."
                        />
                      </div>
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          A Journey of DSA Prodigy
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      Exploring the World of Data Structures and Algorithms:
                      Solving Over 300 DSA Problems on Leetcode, HackerRank,
                      GeeksforGeeks, and Codechef.
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          11th Place in Codechef Coding Competition
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <img
                        src="20DCS083_NaitikPatel-1.jpg"
                        alt=""
                      ></img>
                      <p style={{ fontSize: "16px" }}>
                        Achieving 11th Rank in CODEPIE 2.O: A Team Effort
                        Celebrated at CHARUSAT University in Collaboration with
                        Codechef
                      </p>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </div>

              {/* Experience Content */}

              <div
                className={
                  toggleTab === 3 ? "content active-content" : "content"
                }
              >
                <Card
                  Width="200px"
                  // marginTop={"20px"}
                  bg={"white"}
                  padding={"1.5rem"}
                >
                  <div className="exp-column">
                    <h3>ReactJS Developer</h3>
                    <span>May 2022- June 2022</span>
                    <p>
                      I was an intern at Webbrains technologies for 2 months
                      where I honed my skills in ReactJS. During my time there,
                      I gained valuable hands-on experience working with the
                      technology and contributing to various projects. It was a
                      fantastic opportunity for me to grow both professionally
                      and personally.
                    </p>
                  </div>
                  <div className="exp-column">
                    <h3>MERN stack Developer</h3>
                    <span>May 2023- June 2023</span>
                    <p>
                    During my two-month internship at Prolog Infotech, I worked on the Memes Web App project using MERN stack technologies. It was a rewarding experience where I gained valuable insights and hands-on experience in full-stack development. Collaborating with a skilled team, I contributed to building interactive and dynamic web solutions.
                    </p>
                  </div>
                </Card>
                {/* <div className="exp-column">
                  <h3>Graphic Designer</h3>
                  <span>2015-2022</span>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Enim error rem dicta perferendis et qui obcaecati labore
                    accusantium cupiditate libero.
                  </p>
                </div>

                <div className="exp-column">
                  <h3>Photoshop</h3>
                  <span>2017-2022</span>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Enim error rem dicta perferendis et qui obcaecati labore
                    accusantium cupiditate libero.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
