import React from "react";
import { Box, chakra, Flex } from "@chakra-ui/react";
import { BsGithub } from "react-icons/bs";
import { BiLink } from "react-icons/bi";

const Card1 = ({ key, project, i }) => {
  return (
    <div>
      <Box
        margin={"1rem"}
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        mx={{
          lg: 8,
        }}
        display={{
          lg: "flex",
        }}
        maxW={{
          lg: "5xl",
        }}
        shadow={{
          lg: "lg",
        }}
        rounded={{
          lg: "lg",
        }}
      >
        {/* <Flex direction={i % 2 === 0 ? "row" : "row-reverse"}> */}
          <Box
            w={{
              lg: "50%",
            }}
          >
            <Box
              h={{
                base: 64,
                lg: "full",
              }}
              rounded={{
                lg: "lg",
              }}
              bgSize="cover"
              style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundImage:
                  `url("${project.Imageurl}")`,
              }}
            ></Box>
          </Box>

          <Box
            py={15}
            px={8}
            maxW={{
              base: "xl",
              lg: "5xl",
            }}
            w={{
              lg: "50%",
            }}
          >
            <chakra.h2
              fontSize={{
                base: "2xl",
                md: "3xl",
              }}
              color="gray.800"
              _dark={{
                color: "white",
              }}
              fontWeight="bold"
            >
              {project.ProjectName}
              {/* <chakra.span
              color="brand.600"
              _dark={{
                color: "brand.400",
              }}
            >
              Idea
            </chakra.span> */}
            </chakra.h2>
            <chakra.p
              textAlign={"justify"}
              mt={4}
              color="gray.600"
              _dark={{
                color: "gray.400",
              }}
            >
              {project.Description}
            </chakra.p>
            <h5>
              {project.Technologies.map(function (p, i) {
                return (
                  //   <h6 className="box-item" key={i} >{p}</h6>
                  <button
                    key={i}
                    type="button"
                    className="btn btn btn-secondary m-1 btn-sm"
                  >
                    {p}
                  </button>
                );
              })}
            </h5>

            <container className="my-3">
              {project.GithubLink !== "" ?
              <a
                href={`${project.GithubLink}`}
                className="btn btn-dark btn-sm m-1"
                style={{ width: "44px" }}
              >
                <BsGithub size="2em" />{" "}
              </a>: " "}
              <a
                href={`${project.DemoLink}`}
                className="btn btn-dark btn-sm m-1"
                style={{ width: "44px" }}
              >
                <BiLink size="2em" />{" "}
              </a>
            </container>
          </Box>
        {/* </Flex> */}
      </Box>
    </div>
  );
};

export default Card1;
